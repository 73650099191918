import { graphql, useStaticQuery } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { useEffect, useMemo, useState } from "react";
import { TokenImage } from "./types";

export function useTokenImages(): TokenImage[] {
  const data = useStaticQuery<{
    allFile: {
      edges: {
        node: {
          id: string;
          name: string;
          childImageSharp: { gatsbyImageData: IGatsbyImageData };
        };
      }[];
    };
  }>(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing_tokens" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 410)
            }
          }
        }
      }
    }
  `);

  return useMemo(
    () =>
      data.allFile.edges
        .sort((a, b) =>
          parseInt(a.node.name) > parseInt(b.node.name) ? 1 : -1
        )
        .map(({ node }) => ({
          id: node.id,
          gatsbyImageData: node.childImageSharp.gatsbyImageData,
        })),
    []
  );
}

export function useCarouselIndex(itemCount: number) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((value) => (1 + value) % itemCount);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [itemCount]);

  return activeIndex;
}
