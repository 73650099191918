import React from "react";
import Accordion from "../Accordion";

export default function FAQ({
  question,
  answer,
  index,
  secondColumn,
}: {
  question: string;
  answer: React.ReactNode;
  index: number;
  secondColumn?: boolean;
}) {
  return (
    <li
      className={`py-8 border-t-2 border-b-2 border-t-transparent border-b-gray last:border-b-transparent ${
        secondColumn ? "first:border-t-gray  first:lg:border-t-transparent" : ""
      }`}
    >
      <Accordion
        label={question}
        labelId={`faq-${index}`}
        labelClassName="text-xl lg:text-2xl"
        content={
          <div className="font-light text-sm lg:text-base pt-4">{answer}</div>
        }
        contentId={`faq-answer-${index}`}
      />
    </li>
  );
}
