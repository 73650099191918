import React from "react";
import ChevronRightIcon from "../../../icons/ChevronRight";

export default function Filter({
  items,
  label,
}: {
  items: { label: string; count: number }[];
  label: string;
}) {
  return (
    <div className="bg-gray-light shadow-layer-gray-light p-2 mb-4">
      <div className="flex items-center justify-between px-2 py-1">
        {label}
        <span className="flex transform rotate-90">
          <ChevronRightIcon />
        </span>
      </div>
      <ul className="bg-black py-1 mt-1">
        {items.map(({ label, count }) => (
          <li key={label} className="flex justify-between py-1.5 px-3">
            <span className="flex items-center">
              <span className="w-4 h-4 border-2 border-white mr-3" />
              <span>{label}</span>
            </span>
            <span>{count}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export const filterValues = [
  [
    {
      label: "Background",
      items: [
        { label: "Blue", count: 1012 },
        { label: "Gray", count: 2912 },
        { label: "Green", count: 1836 },
        { label: "Red", count: 1725 },
        { label: "Yellow", count: 2315 },
      ],
    },
    {
      label: "Skintone",
      items: [
        { label: "001", count: 1753 },
        { label: "002", count: 1256 },
        { label: "003", count: 979 },
        { label: "004", count: 1932 },
        { label: "005", count: 1349 },
      ],
    },
    {
      label: "Clothing",
      items: [
        { label: "Blue hoodie", count: 2254 },
        { label: "Green hooodie", count: 1888 },
        { label: "Band shirt", count: 1960 },
        { label: "NASA shirt", count: 1969 },
        { label: "Red hoodie", count: 1977 },
      ],
    },
  ],
  [
    {
      label: "Background",
      items: [
        { label: "Green", count: 990 },
        { label: "Beige", count: 2114 },
        { label: "Apocalypse", count: 838 },
        { label: "Neon", count: 1925 },
        { label: "Starry night", count: 1219 },
      ],
    },
    {
      label: "Specials",
      items: [
        { label: "Balloons", count: 1650 },
        { label: "Baseball hat", count: 2156 },
        { label: "Halo", count: 996 },
        { label: "Rainbow", count: 1999 },
        { label: "Skateboard", count: 940 },
      ],
    },
    {
      label: "Tattoo",
      items: [
        { label: "Anchor", count: 864 },
        { label: "Heart", count: 2052 },
        { label: "Kiss", count: 1556 },
        { label: "Rose", count: 1902 },
        { label: "Smiley face", count: 2008 },
      ],
    },
  ],
  [
    {
      label: "BACKGROUND",
      items: [
        { label: "Pink", count: 912 },
        { label: "Blue", count: 2945 },
        { label: "Purple", count: 1743 },
        { label: "Green clouds", count: 981 },
        { label: "Blue clouds", count: 2510 },
      ],
    },
    {
      label: "CLOTHING",
      items: [
        { label: "Red cardigan", count: 2157 },
        { label: "Orange shirt", count: 951 },
        { label: "Blue jacket", count: 2912 },
        { label: "Green sweater", count: 1932 },
        { label: "Blue turtleneck", count: 941 },
      ],
    },
    {
      label: "FACE",
      items: [
        { label: "Blue beard", count: 2001 },
        { label: "Pink beard", count: 1325 },
        { label: "Plain face", count: 2033 },
        { label: "Big smile", count: 1901 },
        { label: "Mask", count: 523 },
      ],
    },
  ],
];
