import React from "react";
import PageHead from "../components/PageHead";
import HomePage from "../components/HomePage";

export default function Home() {
  return <HomePage />;
}

export const Head = () => (
  <PageHead
    title="Lasagna - NFT collection generator"
    description="Use lasagna to generate your NFT collection of up to 20,000 tokens in seconds without a single line of code."
    relativePath="/"
  />
);
