import React from "react";
import Header from "../Header";
import CheckmarkIcon from "../../icons/Checkmark";
import FAQ from "./FAQ";
import HeaderDemo from "./HeaderDemo";
import { checkmarkItems, faqs } from "./constants";
import { useCarouselIndex, useTokenImages } from "./hooks";
import MobileHeaderImages from "./MobileHeaderImages";
import LinkButton from "../LinkButton";
import ChevronRightIcon from "../../icons/ChevronRight";
import { useState } from "react";
import { useEffect } from "react";
import IconGrid from "./IconGrid";
import NavContent from "../NavContent";

export default function HomePage() {
  const [mainCarouselIndex, setMainCarouselIndex] = useState(0);
  const tokenImages = useTokenImages();
  const carouselIndex = useCarouselIndex(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMainCarouselIndex(carouselIndex);
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [carouselIndex]);

  return (
    <>
      <Header className="shadow-lg fixed" />
      <main className="layout-container py-20">
        <section className="lg:text-center py-8 lg:py-16">
          <h1 className="text-4xl md:text-5xl lg:text-6xl mb-4">
            Create your NFT collection in seconds.
          </h1>
          <H2 className="text-gradient mb-12">No code? Yes, chef.</H2>
          <ul className="block items-center justify-center mb-8 -mx-4 text-xl lg:text-base lg:flex">
            {checkmarkItems.map((item) => (
              <li key={item} className="flex items-center px-4 py-2">
                <CheckmarkIcon />
                <span className="ml-2">{item}</span>
              </li>
            ))}
          </ul>
          <div className="mb-12">
            <LinkButton
              href="https://app.lasagna.xyz"
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              className="w-full sm:w-auto"
            >
              <span className="mr-3">Start for free</span>
              <ChevronRightIcon />
            </LinkButton>
          </div>
          <div className="hidden lg:block">
            <HeaderDemo
              tokenImages={tokenImages}
              buttonCarouselIndex={carouselIndex}
              carouselIndex={mainCarouselIndex}
            />
          </div>
          <div className="block lg:hidden">
            <MobileHeaderImages
              tokenImages={tokenImages}
              carouselIndex={carouselIndex}
            />
          </div>
          {/* <Quotes /> */}
        </section>
        <section className="py-8 lg:py-16">
          <H2 className="text-gradient py-2">Generate your NFT collection</H2>
          <H2>Everything you need in one place</H2>
          <IconGrid />
        </section>
        <section className="py-8 lg:py-16">
          <H2 className="text-gradient py-2">Have any questions?</H2>
          <H2 className="mb-8">Let us explain</H2>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
            <ul>
              {faqs.slice(0, faqs.length / 2).map((faq, index) => (
                <FAQ {...faq} key={faq.question} index={index} />
              ))}
            </ul>
            <ul>
              {faqs.slice(faqs.length / 2).map((faq, index) => (
                <FAQ {...faq} key={faq.question} index={index} secondColumn />
              ))}
            </ul>
          </div>
        </section>
        <section className="py-8 lg:py-16">
          <H2 className="text-left md:text-center mb-12">{`No need to wait - let's get started`}</H2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:gap-12 max-w-screen-md m-auto">
            <div>
              <LinkButton
                href="/guides/welcome"
                size="large"
                variant="gray"
                fullWidth
              >
                <span className="mr-3">View guides</span>
                <ChevronRightIcon size={9} />
              </LinkButton>
            </div>
            <div>
              <LinkButton
                href="https://app.lasagna.xyz"
                rel="noopener noreferrer"
                target="_blank"
                size="large"
                fullWidth
              >
                <span className="mr-3">Launch app</span>
                <ChevronRightIcon size={9} />
              </LinkButton>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="px-0 xl:px-3 max-w-screen-xl m-auto">
          <span className="h-px bg-gray block" />
        </div>
        <div className="py-8">
          <NavContent />
        </div>
      </footer>
    </>
  );
}

function H2({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h2 className={`text-3xl md:text-5xl lg:text-6xl ${className}`}>
      {children}
    </h2>
  );
}
