import React, { useMemo } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Carousel from "./Carousel";
import type { TokenImage } from "./types";

export default function MobileHeaderImages({
  tokenImages,
  carouselIndex,
}: {
  tokenImages: TokenImage[];
  carouselIndex: number;
}) {
  return (
    <div className="relative mt-28 md:mt-32">
      <Carousel
        activeIndex={carouselIndex}
        items={Array.from({ length: 3 }).map((v, i) => (
          <CarouselItem index={i} tokenImages={tokenImages} key={i} />
        ))}
      />
    </div>
  );
}

function CarouselItem({
  tokenImages,
  index,
}: {
  tokenImages: TokenImage[];
  index: number;
}) {
  const images = useMemo(
    () => tokenImages.slice(index * 12, 12 + index * 12),
    [tokenImages, index]
  );

  return (
    <div className="relative">
      <div className="grid grid-cols-4 gap-1">
        {Array.from({ length: 4 }).map((v, i) => (
          <div key={i} className={classes[i]}>
            <GridCol images={images.slice(i * 3, i * 3 + 3)} />
          </div>
        ))}
      </div>
    </div>
  );
}

const classes = ["-mt-12", "-mt-4", "-mt-8", ""];

function GridCol({ images }: { images: TokenImage[] }) {
  return (
    <div>
      {images.map((image) => (
        <div className="mb-1" key={image.id}>
          <GatsbyImage image={image.gatsbyImageData} alt="Token example" />
        </div>
      ))}
    </div>
  );
}
