import React from "react";
import type { ReactNode } from "react";
import { useSpring, animated } from "react-spring";

export default function Carousel({
  items,
  activeIndex,
}: {
  items: JSX.Element[];
  activeIndex: number;
}) {
  return (
    <div className="relative">
      {items.map((item, index) => (
        <CarouselItem isActive={activeIndex === index} key={`${index}`}>
          {item}
        </CarouselItem>
      ))}
    </div>
  );
}

function CarouselItem({
  isActive,
  children,
}: {
  isActive: boolean;
  children: ReactNode;
}) {
  const { opacity } = useSpring({
    opacity: isActive ? 1 : 0,
    config: { duration: 300 },
  });

  return (
    <animated.div
      style={{ opacity }}
      className={isActive ? "relative" : "absolute inset-0"}
    >
      {children}
    </animated.div>
  );
}
