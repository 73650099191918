import React from "react";

export default function Rotate() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.3334C8.95896 13.3335 9.9002 13.075 10.7246 12.5852C11.5491 12.0953 12.2262 11.3923 12.6847 10.55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 7.99996C13.3333 6.58547 12.7714 5.22892 11.7712 4.22872C10.771 3.22853 9.41449 2.66663 8 2.66663"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99997 2.66663C7.04101 2.66655 6.09977 2.92502 5.27535 3.41485C4.45092 3.90467 3.77381 4.60771 3.31531 5.44996"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66663 8C2.66663 9.41449 3.22853 10.771 4.22872 11.7712C5.22892 12.7714 6.58547 13.3333 7.99996 13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.31531 5.45005C3.88202 4.4086 4.7796 3.58568 5.86623 3.11133C6.95286 2.63698 8.16657 2.53824 9.31558 2.83071C10.4646 3.12318 11.4834 3.79019 12.211 4.72634C12.9386 5.66248 13.3335 6.81439 13.3333 8.00005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66663 8C2.66644 9.18565 3.06135 10.3376 3.78895 11.2737C4.51654 12.2099 5.53534 12.8769 6.68435 13.1693C7.83337 13.4618 9.04708 13.3631 10.1337 12.8887C11.2203 12.4144 12.1179 11.5914 12.6846 10.55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.36472 5.44998H3.00806V3.09265"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6354 10.55H12.992V12.9074"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
