import React from "react";

export const checkmarkItems = [
  "20,000 tokens per collection",
  "Unlimited trait layers",
  "No royalties, ever",
];

export const faqs = [
  {
    question: "What is an NFT?",
    answer:
      "An NFT (non-fungible token) is a way to prove ownership of a digital asset. An asset could be anything ranging from art, music, videos, or any digital asset. Ownership of an NFT is proven by utilizing the blockchain protocol, which allows anyone to view the current owner of an NFT.",
  },
  {
    question: "What is so special about lasagna?",
    answer:
      "Lasagna is the easiest way to generate an NFT collection. Just use our simple drag-and-drop user interface to create your collection in seconds - all without having to write a single line of code. No expertise is necessary to build your collection with lasagna.",
  },
  {
    question: "What is a token?",
    answer:
      "A token is an individual NFT associated with your collection. Each token is unique and when created through lasagna includes a token image (the layered output of your artwork) and a token metadata file (which include all it's trait information, description, etc).",
  },
  {
    question: "How do I get started?",
    answer: (
      <>
        Simply{" "}
        <a
          href="https://app.lasagna.xyz"
          rel="noopener noreferrer"
          target="_blank"
          className="text-primary hover:underline"
        >
          launch the app
        </a>{" "}
        and sign in using your wallet or email address. You can then create your
        collection, upload your assets and generate your tokens within seconds.
      </>
    ),
  },
  {
    question: "Can I set rarity?",
    answer:
      "For sure. You can set rarity on an individual asset or on an entire layer...so it's easy to make those 3D glasses more rare and that hoodie more common.",
  },
  {
    question: "What does it cost to create a collection?",
    answer:
      "Tinkering is free. Downloading and launching your collection costs $4.99 plus $0.01 per token.",
  },
  {
    question: "Can I set rules?",
    answer: `Yes...or as we like to call them, recipes! You can create as many as you want. With each recipe you further customize and control how our generator will render your combinations. Make the generator smarter by saying which assets should "never", "only", or "always" be combined next time you generate your tokens.`,
  },
  {
    question: "What do I get when I use lasagna?",
    answer:
      "Lasagna offers you everything needed to generate your next NFT collection. When you complete your collection in lasagna, you can receive a completed collection package. This package will include the final render and a neatly organized metadata file of each token in your collection. Each image will be rendered in its original size. Each metadata file will include the token ID, name, description, image reference, and all trait definitions for that token.",
  },
  {
    question: "Can I tweak results?",
    answer:
      "Of course. When you click on individual tokens you can fully customize the result by dragging layers into different orders, find better alternative assets, or turn on/off layers all together. You have full control to overwrite any combination to your preference.",
  },
  {
    question: "Can I mint my collection through lasagna?",
    answer: (
      <>
        You bet. We offer no-code smart contract management and deployment tools
        using a custom integration with{" "}
        <a
          href="https://thirdweb.com"
          rel="noopener noreferrer"
          target="_blank"
          className="text-primary hover:underline"
        >
          thirdweb
        </a>
        . Create your smart contract and upload your NFTs all with the click of
        a button.
      </>
    ),
  },
];
