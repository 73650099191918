import React, { useEffect, useState } from "react";
import LottieIcon from "./LottieIcon";
import manageTraits from "./LottieIcon/animations/upload-asset.json";
import setRarities from "./LottieIcon/animations/new-collection.json";
import createRecipes from "./LottieIcon/animations/new-recipe.json";
import tweakTokens from "./LottieIcon/animations/regenerate-tokens.json";
import customTokens from "./LottieIcon/animations/new-layer.json";
import sharedPreviews from "./LottieIcon/animations/share-preview.json";
import imagesPackaged from "./LottieIcon/animations/package-image.json";
import metadataPackaged from "./LottieIcon/animations/package-metadata.json";
import freeToTry from "./LottieIcon/animations/generate-tokens.json";

function IconGrid() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((value) => {
        return (value + 1) % iconGridItems.length;
      });
    }, duration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ul className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
      {iconGridItems.map(({ animationData, label, text }, index) => (
        <li key={label} className="flex items-center">
          <div className="w-20 flex-shrink-0 mr-2">
            <LottieIcon
              animationData={animationData}
              isActive={index === activeIndex}
            />
          </div>
          <div className="flex-grow">
            <h5 className="text-xl">{label}</h5>
            <p className="font-light text-sm">{text}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default React.memo(IconGrid);

const iconGridItems = [
  {
    animationData: manageTraits,
    label: "Manage your traits",
    text: "Drag and drop up to 500 assets to compose your tokens.",
  },
  {
    animationData: setRarities,
    label: "Set rarities",
    text: "From common to rare, set rarity per trait or for whole layers.",
  },
  {
    animationData: createRecipes,
    label: "Create recipes",
    text: "Command precision and control over the generation process.",
  },
  {
    animationData: tweakTokens,
    label: "Tweak your tokens",
    text: "Re-order layers and change individual traits for each token.",
  },
  {
    animationData: customTokens,
    label: "Randomize layers",
    text: "Let our generation process create unique and interesting tokens every time.",
  },
  {
    animationData: sharedPreviews,
    label: "Share previews",
    text: "Collaborate with your mates via secure shared previews.",
  },
  {
    animationData: imagesPackaged,
    label: "Download images",
    text: "Receive an organized package with all your token images.",
  },
  {
    animationData: metadataPackaged,
    label: "Download metadata",
    text: "Receive an organized package with all your token metadata.",
  },
  {
    animationData: freeToTry,
    label: "Start for free",
    text: "Lasagna is free to use, only pay when your collection is done.",
  },
];

const duration = 3516.6666666666667;
