import React from "react";

export default function Checkmark() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1935_313937)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="white"
        />
        <path
          d="M6.57916 9.84634L6.04883 9.31601L4.98817 10.3767L5.5185 10.907L6.57916 9.84634ZM8.45661 12.7844L7.92628 13.3148L8.45813 13.8466L8.98845 13.3133L8.45661 12.7844ZM14.4051 7.86548L14.9339 7.33363L13.8702 6.27601L13.3414 6.80786L14.4051 7.86548ZM5.5185 10.907L7.92628 13.3148L8.98694 12.2541L6.57916 9.84634L5.5185 10.907ZM8.98845 13.3133L14.4051 7.86548L13.3414 6.80786L7.92476 12.2556L8.98845 13.3133Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1935_313937">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
