import React, { useCallback } from "react";
import { animated, useSpring } from "react-spring";
import { footerButtonStyles } from ".";
import RotateIcon from "../../../icons/Rotate";

let hasRenderedOnce = false;

export default function RegenerateButton({
  carouselIndex,
}: {
  carouselIndex: number;
}) {
  const styles = useSpring({
    to: useCallback(
      async (next) => {
        await next({ scale: 0.95 });
        await next({ scale: 1.03 });
        await next({ scale: 1 });
      },
      [carouselIndex]
    ),
    from: { scale: 1 },
    config: {
      duration: 150,
      tension: 100,
    },
  });

  if (!hasRenderedOnce && carouselIndex > 0) {
    hasRenderedOnce = true;
  }

  return (
    <animated.div
      className={`${footerButtonStyles} will-change-transform`}
      style={{
        transform: hasRenderedOnce
          ? styles.scale.to((val) => `scale(${val})`)
          : undefined,
      }}
    >
      <RotateIcon />
      <span className="ml-2">Regenerate tokens</span>
    </animated.div>
  );
}
