import React, { useState, useRef } from "react";
import type { ReactNode, Dispatch, SetStateAction } from "react";
import { animated, useSpring } from "react-spring";
import AccordionIcon from "./AccordionIcon";
import { useLayoutEffect } from "react";
import { useEffect } from "react";

export default function Accordion({
  label,
  labelId,
  labelClassName = "",
  content,
  contentId,
  iconSize,
  expanded,
  setExpanded,
}: {
  label: string;
  expanded?: boolean;
  setExpanded?: Dispatch<SetStateAction<boolean>>;
  labelId: string;
  labelClassName?: string;
  content: ReactNode;
  contentId: string;
  iconSize?: "normal" | "small";
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const dispatch = setExpanded || setOpen;

  const isOpen = expanded === undefined ? open : expanded;

  const toggle = () => {
    dispatch((value) => !value);
  };

  const { height, opacity } = useSpring({
    height:
      isOpen && ref.current ? ref.current.getBoundingClientRect().height : 0,
    opacity: isOpen ? 1 : 0,
    config: {
      duration: 150,
    },
  });

  return (
    <>
      <button
        onClick={toggle}
        aria-expanded={isOpen}
        aria-controls={contentId}
        id={labelId}
        className={`flex items-center w-full text-left transition-colors ${
          isOpen ? "text-primary" : "text-white"
        } ${labelClassName}`}
      >
        <span className="flex-grow">{label}</span>
        <AccordionIcon open={isOpen} size={iconSize} />
      </button>
      <animated.div
        className="overflow-hidden"
        style={{
          height,
          opacity,
        }}
        id={contentId}
        aria-labelledby={labelId}
      >
        <div ref={ref}>{content}</div>
      </animated.div>
    </>
  );
}
