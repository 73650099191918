import React, { useMemo } from "react";
import ArrowDownIcon from "../../../icons/ArrowDown";
import EthereumIcon from "../../../icons/Ethereum";
import LargeGridIcon from "../../../icons/LargeGrid";
import LogoIcon from "../../../icons/Logo";
import ChevronRightIcon from "../../../icons/ChevronRight";
import SmallGridIcon from "../../../icons/SmallGrid";
import type { TokenImage } from "../types";
import Carousel from "../Carousel";
import Filter, { filterValues } from "./Filter";
import TokenCard from "./TokenCard";
import RegenerateButton from "./RegenerateButton";

function HeaderDemo({
  tokenImages,
  carouselIndex,
  buttonCarouselIndex,
}: {
  tokenImages: TokenImage[];
  carouselIndex: number;
  buttonCarouselIndex: number;
}) {
  const items = useMemo(
    () =>
      Array.from({ length: 3 }).map((v, index) => (
        <CarouselItem
          tokenImages={tokenImages}
          carouselIndex={carouselIndex}
          index={index}
          key={index}
        />
      )),
    [carouselIndex, tokenImages]
  );

  return (
    <div
      className="bg-gray-dark py-4 px-6 cursor-default font-secondary relative text-sm"
      role="presentation"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <span className="w-28 mr-6">
            <LogoIcon />
          </span>
          <ul className="flex items-center">
            {navItems.map((item) => (
              <li key={item} className="opacity-80 px-3">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center">
          <span className="flex items-center mr-6">
            <span className="w-2 flex mr-2">
              <EthereumIcon />
            </span>
            <span>4.20 ETH</span>
          </span>
          <span className="bg-gray-light py-1.5 px-3 shadow-layer-gray-light-small">
            0x4d9...p7FgR
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center text-sm mb-4">
        <div className="flex-shrink-0 w-64 text-left pl-1 opacity-80">
          <span>10,000 tokens</span>
        </div>
        <div className="flex-grow flex items-center justify-between">
          <div className="flex items-center">
            <span className="flex text-primary">
              <LargeGridIcon />
            </span>
            <span className="flex ml-4 mr-8">
              <SmallGridIcon />
            </span>
            <span className="text-primary flex items-center">
              <span className="mr-2">Tokens</span>
              <ArrowDownIcon />
            </span>
          </div>
          <div>
            <span className="px-3 py-2 bg-gray-light shadow-layer-gray-light-small inline-flex items-center justify-center">
              <span className="mr-3">Share access</span>
              <ChevronRightIcon />
            </span>
          </div>
        </div>
      </div>
      <div className="relative">
        <Carousel activeIndex={carouselIndex} items={items} />
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between px-6 py-4 bg-gray-dark border-t border-black">
        <RegenerateButton carouselIndex={buttonCarouselIndex} />
        <div className={footerButtonStyles}>
          <span className="mr-2">My collection is ready</span>
          <ChevronRightIcon />
        </div>
      </div>
    </div>
  );
}

export default React.memo(HeaderDemo);

function CarouselItem({
  tokenImages,
  index,
  carouselIndex,
}: {
  tokenImages: TokenImage[];
  index: number;
  carouselIndex;
}) {
  const images = useMemo(() => {
    return tokenImages.slice(index * 12, index * 12 + 12);
  }, [index, tokenImages]);

  return (
    <div className="flex">
      <div className="w-64 flex-shrink-0 overflow-hidden relative">
        <div className="absolute top-0 left-0 right-6">
          {filterValues[index].map(({ label, items }) => (
            <Filter key={label} items={items} label={label} />
          ))}
        </div>
      </div>
      <div className="flex-grow grid grid-cols-4 gap-2">
        {images.map((image, imageIndex) => {
          return (
            <TokenCard
              index={imageIndex}
              tokenImage={image}
              key={image.id}
              carouselIndex={carouselIndex}
            />
          );
        })}
      </div>
    </div>
  );
}

const navItems = ["Assets", "Recipes", "Tokens"];
export const footerButtonStyles =
  "bg-white shadow-layer-white text-black py-2 px-4 flex items-center justify-center";
