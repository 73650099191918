import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { TokenImage } from "../types";

export default function TokenCard({
  index,
  tokenImage,
  carouselIndex,
}: {
  index: number;
  tokenImage: TokenImage;
  carouselIndex: number;
}) {
  return (
    <div>
      <GatsbyImage image={tokenImage.gatsbyImageData} alt="Token example" />
      <div className="bg-black flex items-center justify-between py-1 px-2">
        <span>{index + 1}</span>
        <span className="opacity-50">#{ranks[carouselIndex][index]}</span>
      </div>
    </div>
  );
}

const ranks = {
  0: {
    0: 42,
    1: 139,
    2: 98,
    3: 2314,
    4: 94,
    5: 18,
    6: 1,
    7: 77,
    8: 3124,
    9: 178,
    10: 99,
    11: 995,
  },
  1: {
    0: 239,
    1: 461,
    2: 98,
    3: 7,
    4: 951,
    5: 481,
    6: 13,
    7: 1,
    8: 552,
    9: 13,
    10: 987,
    11: 11,
  },
  2: {
    0: 80,
    1: 2,
    2: 54,
    3: 872,
    4: 1219,
    5: 16,
    6: 8,
    7: 194,
    8: 1026,
    9: 842,
    10: 13,
    11: 49,
  },
};
