import React, { useEffect, useRef } from "react";
import lottie, { AnimationItem } from "lottie-web";

export default function LottieIcon({
  animationData,
  translateX = "-translate-x-6",
  isActive,
}: {
  animationData: any;
  translateX?: string;
  isActive: boolean;
}) {
  const elementRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      animationRef.current = lottie.loadAnimation({
        container: elementRef.current,
        animationData,
      });

      const animation = animationRef.current;

      animation.pause();

      return () => {
        animation.destroy();
      };
    }
  }, [animationData]);

  useEffect(() => {
    if (animationRef.current && isActive) {
      const animation = animationRef.current;
      animation.play();

      return () => {
        animation.stop();
      };
    }
  }, [isActive]);

  return (
    <div
      className={`h-28 w-28 -mt-6 -mb-4 lottie-icon transform ${translateX}`}
      ref={elementRef}
    />
  );
}
