import React from "react";

export default function AccordionIcon({
  open,
  size = "normal",
}: {
  open: boolean;
  size?: "normal" | "small";
}) {
  return (
    <span className="flex items-center justify-center relative flex-shrink-0 flex-grow-0">
      <span
        className={`${
          sizeStyles[size]
        } transform transition rotate-90 absolute -translate-y-4 ${
          open ? "scale-0 bg-primary" : "scale-1 bg-white"
        }`}
      />
      <span
        className={`${sizeStyles[size]} transition-colors ${
          open ? "bg-primary" : "bg-white"
        }`}
      />
    </span>
  );
}

const sizeStyles = {
  normal: "w-4 md:w-5 h-0.5 right-0 top-4",
  small: "w-4 h-0.5 right-0 top-4",
};
