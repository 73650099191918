import React from "react";

export default function Ethereum() {
  return (
    <svg
      width="100%"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 6.8477L0 5.10035L2.99415 0L6 5.10035L2.99415 6.8477Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 6.84768L0 5.10034L2.99415 3.71899L6 5.10034L2.99415 6.84768Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 9.99998L6 5.72607L2.99415 7.57967L0 5.72607L2.99415 9.99998Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 7.57967V9.99998L0 5.72607L2.99415 7.57967Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 0V6.8477L0 5.10035L2.99415 0Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99415 3.71899V6.84768L0 5.10034L2.99415 3.71899Z"
        fill="white"
      />
    </svg>
  );
}
